// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/notifications/components/Notification.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/notifications/components/Notification.tsx");
  import.meta.hot.lastModified = "1728419989067.4634";
}
// REMIX HMR END

import React from 'react';
import { Avatar, Link } from '~ui';
import { HistoryEntryType, NotificationType, RsvMembershipStatus } from '~admin/generated/graphql';
import { prettyDateFormat } from '~common/utils/dateHelper';
import { config as orderConfig } from '~admin/modules/order/config';
import { config as customerConfig } from '~admin/modules/customer/config';
import { notificationTitle } from '../config';
import { NotificationIcon } from './NotificationIcon';
export const dataTranslate = entry => {
  switch (entry.type) {
    case HistoryEntryType.SubscriptionChange:
      return <span className="block">
          <span className="block">
            From:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.from}
            </span>
            , to:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.to === RsvMembershipStatus.Producttransition ? entry.data.from === RsvMembershipStatus.Trial ? 'ENROLLMENT' : 'UPGRADE' : entry.data.to}
            </span>
          </span>
          <span className="block font-light dark:text-slate-300">
            <Link to={`${customerConfig.path}/${entry.data.assignedCustomerId}/details`}>
              {entry.data.assignedCustomerFirstName}{' '}
              {entry.data.assignedCustomerLastName}
            </Link>
          </span>
        </span>;
    case HistoryEntryType.MembershipStateTransition:
      return <span className="block">
          <span className="block">
            From:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.from}
            </span>
            , to:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.to === RsvMembershipStatus.Producttransition ? 'UPGRADE' : entry.data.to}
            </span>
          </span>
          {entry.data.optionalProductVariantName ? <span className="font-light dark:text-slate-300">
              Product: {entry.data.optionalProductVariantName}
            </span> : null}
        </span>;
    case HistoryEntryType.OrderStateTransition:
    case HistoryEntryType.OrderPaymentTransition:
    case HistoryEntryType.OrderRefundTransition:
    case HistoryEntryType.OrderFulfillmentTransition:
    case HistoryEntryType.ParticipantStateTransition:
      return <span className="block">
          {entry.data.from ? <>
              From:{' '}
              <span className="font-light dark:text-slate-300">
                {entry.data.from}
              </span>
              ,{' '}
            </> : null}
          to:{' '}
          <span className="font-light dark:text-slate-300">
            {entry.data.to}
          </span>
        </span>;
    case HistoryEntryType.CustomerAddressCreated:
    case HistoryEntryType.CustomerAddressUpdated:
    case HistoryEntryType.CustomerAddressDeleted:
      return <span className="block">
          <span className="font-light dark:text-slate-300">
            {entry.data.address}
          </span>
        </span>;
    case HistoryEntryType.OrderCancellation:
      return <span className="block">
          <span className="font-light dark:text-slate-300">
            {entry.data.reason}
          </span>
          , paymentId: {entry.data.paymentId}
        </span>;
    case HistoryEntryType.OrderCouponApplied:
    case HistoryEntryType.OrderCouponRemoved:
      return <span className="block font-light dark:text-slate-300">
          {entry.data.couponCode}
        </span>;
    case HistoryEntryType.OrderNote:
    // TODO integrate into history server side
    case HistoryEntryType.StaffNote:
    case HistoryEntryType.ParticipantNote:
    case HistoryEntryType.ParticipantMessage:
      return <span className="block w-full font-light text-ellipsis dark:text-slate-300">
          {entry.data.note}
        </span>;
    case HistoryEntryType.ParticipantCheckin:
      return <span className="block w-full font-light text-ellipsis dark:text-slate-300">
          Status:{' '}
          {entry.data.status == 'PRESENT' ? entry.data.status : 'ABSENT'},{' '}
          {entry.data.className}, {entry.data.groupName}.
        </span>;
    case HistoryEntryType.AgreementSigned:
      return <span className="block w-full font-light text-ellipsis dark:text-slate-300">
          {entry.data.label}
        </span>;
    default:
      return null;
  }
};
export const NotificationDetail = ({
  item,
  displayCustomer = true,
  displayAvatar = true
}) => {
  let historyItem;
  let customer;
  let order;
  let administrator;
  if ('order' in item) {
    historyItem = item;
    order = historyItem.order;
    customer = historyItem.order.customer;
  }
  if ('customer' in item) {
    historyItem = item;
    customer = historyItem.customer;
  }
  if ('administrator' in item) {
    administrator = item.administrator;
  }
  return <div className="flex items-start">
      {customer && displayAvatar ? <Avatar source={customer.customFields?.image?.preview} type="circular" size="xs" altContent={`${customer.firstName} ${customer.lastName}`} className="mr-2" /> : displayAvatar ? <div className="w-11" /> : null}
      <p className="truncate text-sm">
        {order ? <>
            <span className="block font-light text-gray-700 dark:text-slate-400">
              Order:{' '}
              <Link to={`${orderConfig.path}/${order.id}`} className="font-semibold dark:text-slate-300">
                {order.code}
              </Link>
            </span>
          </> : null}
        {displayCustomer && customer ? <>
            <span className="block font-light text-gray-700 dark:text-slate-400">
              Customer:{' '}
              <Link to={`${customerConfig.path}/${customer.id}`} className="font-semibold dark:text-slate-300">
                {customer.firstName} {customer.lastName}
              </Link>
            </span>
            <span className="block text-gray-500 dark:text-slate-300">
              {customer.emailAddress}
            </span>
          </> : null}
        {dataTranslate(item)}
      </p>
    </div>;
};
_c = NotificationDetail;
export const Notification = ({
  item,
  iconSize = 'small',
  displayAvatar
}) => {
  let administrator;
  if ('administrator' in item) {
    administrator = item.administrator;
  }
  return <li key={item.id} className="w-full relative py-2 px-2">
      <div className="flex items-start gap-x-3 mb-1">
        <NotificationIcon entry={item} iconSize={iconSize} type={NotificationType.Notification} />
        <div className="w-full">
          <div className="flex justify-between mb-0.5">
            <h3 className="mb-1 flex-auto truncate text-base font-medium leading-6 text-gray-400 dark:text-slate-400">
              {notificationTitle(item.type)}
            </h3>
            <time dateTime={item.createdAt} className="flex-none text-xs font-light text-gray-400 dark:text-slate-400">
              {prettyDateFormat(new Date(item.createdAt))}
            </time>
          </div>
          <NotificationDetail item={item} displayAvatar={displayAvatar} />
        </div>
      </div>
    </li>;
};
_c2 = Notification;
var _c, _c2;
$RefreshReg$(_c, "NotificationDetail");
$RefreshReg$(_c2, "Notification");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;